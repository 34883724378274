import { functions } from '@/firestore';

export default class {
  static getGcpCost() {
    return new Promise((resolve, reject) => {
      const getGcpCost = functions.httpsCallable("shepherd/getGcpCost");
      getGcpCost().then((result) => {
        resolve(result.data);
      }).catch(() => {
        reject();
      });
    });
  }
}
