export default class {
  static statusCodeList() {
    return {
      0: 'Not run yet',
      10: 'Last Run Success',
      11: 'Last Run Timeout',
      12: 'Last Run Fail',
      13: 'Last Run Crash',
      14: 'Last Run Unknown',
      50: 'Running',
      60: 'Stopped',
      99: 'Disabled',
    };
  }

  static sqlProxyList() {
    return [
      {
        id: 'di-central',
        name: 'DI Central',
        instance: 'digital-intelligence-208511:europe-west2:central',
        secret: 'cloudsql-instance-credentials',
      },
    ];
  }
}
